import React, { useEffect } from 'react';
import HomeSlider from'../Components/HomeSlider'
import Birthday from'../Components/Birthday'
import ToppersXII from'../Components/ToppersXII'
import Toppers from'../Components/Toppers'
import Events from'../Components/Events'
import News from'../Components/News'
import HomeMessage from'../Components/HomeMessage'
import { Link } from 'react-router-dom'
import Gallery from'../Components/Gallery'
import Notification from'../Components/Notification'
import OnlineReg from'../Components/OnlineReg'
import AlumniRegistration from'../Components/AlumniRegistration'
import LazyLoad from 'react-lazyload';
const Home = () => {
    
    useEffect(() => { 
        document.title = 'Home - St. Francis School, Ranchi ';  
      }, []);
  return (
     <>
       <AlumniRegistration/>
      <OnlineReg/>
      <Notification/>
        <HomeSlider/>
           <div className="container">
                <div className="row">
                       <div class='col-lg-12'>
                       <ul className="list-block">
                       <li>
                           <Link to="/" className="list_blk">
                             <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/motto.gif" alt="St. Francis School, Ranchi" /></LazyLoad>
                           Motto</Link>
                       </li>
                       <li>
                           <Link to="/AdmissionProcedure" className="list_blk">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/admission.gif" alt="St. Francis School, Ranchi" /></LazyLoad>
                           Admission</Link>
                       </li>
                       <li>
                           <Link to="/Circulars" className="list_blk">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/circular.gif" alt="St. Francis School, Ranchi" /></LazyLoad>
                           Circular</Link>
                       </li>
                       <li>
                           <Link to="/ComingSoon" className="list_blk">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/acheivements.gif" alt="St. Francis School, Ranchi" /></LazyLoad> Acheivements</Link>
                       </li>
                      
                       <li>
                           <Link to='/' target="_blank" className="list_blk">
                          <LazyLoad>  <img src="https://webapi.entab.info/api/image/STFSR/public/Images/Book.gif" alt="St. Francis School, Ranchi" /></LazyLoad> Book List</Link>
                       </li> 
                       <li>
                           <Link to="/VideoGallery" className="list_blk">
                           <LazyLoad> <img src="https://webapi.entab.info/api/image/STFSR/public/Images/video.gif" alt="St. Francis School, Ranchi" /></LazyLoad> Video Gallery</Link>
                       </li>
                      </ul> 
                       </div>
                </div>
           </div>
       <div className="about">
       <div className="container-fluid aboutdiv">
           <div className="row">
               <div className="col-md-12 col-lg-6">
                   <div className="about-img">
                       <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/about.jpg" className="img-fluid" alt="St. Francis School, Harmu, Ranchi"/></LazyLoad>
                       <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/aboutimg.jpg" className="kgschool img-fluid" alt="St. Francis School, Harmu, Ranchi"/></LazyLoad>
                   </div>
               </div>
               <div className="col-md-12 col-lg-6">
                    <div className="titlehead">
                         <span>Welcome to</span> <h2>St. Francis School, Harmu, Ranchi</h2> 
                    </div>
                 <div className="aboutdesc">
                 <p><b>St. Francis School, Ranchi, founded in 1978, </b>is an <b>English Medium Co-educational School</b> established and conducted by <b> T.O.R. FRANCISCAN SOCIETY,</b> a registered Charitable Society, having its headquarters at Ranchi, Jharkhand. The School conducts classes from <b>Nursery to XII </b> Standard and is  <b>affiliated to the Council for the Indian School Certificate Examination, New Delhi.</b>  
                   <br/>Managed and taught by dedicated and qualified Sisters, Fathers and Brothers, the T.O.R. Franciscan Society runs several Schools in India and abroad where young pupils belonging to every caste, creed, socio-economic, cultural and linguistic groups are educated through the medium of English and respective regional languages. These institutions are run under the aegis of the Catholic Church in it’s efforts to spread and impart education and literacy to far and wide areas of the Country and to make it easily affordable and approachable to all sections of the Society. <b>Needless to add, the Christian community has always been at the forefront of educational, healthcare and other philanthropical service in the Country.</b></p>
                 </div>
                 <Link to="/OurSchool"> <button className="readmore">Read More</button> </Link> 
               </div>
               </div>
           </div>
       </div>
       <div className="news-event">
       <div className="container-fluid">
         <div className="row">
             <div className="col-xxl-7 col-xl-6 col-lg-6 col-md-12">
                  <div className="news">
                    <div className="mainheading"> 
                        <p> What’s Happening </p> 
                        <h2> Latest News</h2> 
                    <Link to="/News"> <span><i class="bi bi-caret-right-fill"></i></span></Link>
                    </div>
                          <News/> 
                  </div>
                  <div className="events">

                  <div className="mainheading"> 
                        <p> Latest Events</p> 
                        <h2> Upcoming Events </h2> 
                       <Link to="/Events"> <span><i class="bi bi-caret-right-fill"></i></span></Link>
                    </div>
                         <Events/>
                  </div>
             </div>
             <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 greyblk">
                          <div className="acheiver-main">
                             <div className="mainheading">
                             <p> Our Shining Stars </p>
                             <h2>Top Acheivers  </h2> 
                             
                             </div>
                  <div className="row">
                     <div className="col-xl-6 col-lg-12 col-md-12">
                       
                         <Toppers/>
                     </div>
                     <div className="col-xl-6 col-lg-12 col-md-12">
                         
                         <ToppersXII/>
                     </div>
                  </div>           
               </div>
                  <div className="birthday-main">
                          <div className="mainheading"> 
                                <p> Star of the Day</p> 
                                <h2> Happy Birthday </h2> 
                             
                            </div>
                          <Birthday/>
                   </div>
              </div>
         </div>
       </div>
      </div>
      <div className="homemessage">
            <div className="container">
                 <div className="row">
                       <div className="col-lg-12">
                             <HomeMessage/> 
                       </div>
                 </div>
            </div>
      </div>
      <div className="gallery">
      <div className="container-fluid fulwidth">
          <div className="row">
              <div className="col-md-12">
                <div className="mainheading">
                    <p>Some Precious Moments</p>
                    <h2>Photo Gallery  </h2> 
                    
                    </div>
                 <Gallery/> 
                <center> <Link to="/Gallery"> <button className="readmore">View More</button> </Link> </center>
              </div>
          </div>
         </div>
     </div>
   
     </>
  )
}

export default Home
