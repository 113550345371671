import React, { useState, useEffect } from 'react';
import { getBirthdays } from '../Service/Api';
import Slider from "react-slick";
import CustomNextArrow from '../Pages/CustomNextArrow';
import CustomPrevArrow from '../Pages/CustomPrevArrow';
import LazyLoad from 'react-lazyload';

const Birthday = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const birthdayData = await getBirthdays();
        setData(birthdayData);
        console.log(birthdayData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const emptyArray = [
    { name: "Student Name", class: "Class"},
    { name: "Student Name", class: "Class"},
    { name: "Student Name", class: "Class"}
  ];

  var settings = {
    autoplay: true,
    infinite: false,
    autoplaySpeed: 2500,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <Slider {...settings}>
        {data && data.length > 0 ? (data.map((item, index) => (
          <div key={index}>
            <div className="acheiver-blk">
              <div className="acheiver-image">
                <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="St. Francis School, Ranchi" /></LazyLoad>
              </div>
              <div className="content">
                <h3>{item.name}</h3>
                <p>{item.class}</p>
              </div>
              <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/birthday.gif" className="balon-gif" alt="St. Francis School, Ranchi" /></LazyLoad>
            </div>
          </div>
        ))) : (emptyArray.map((item, index) => (
          <div key={index}>
            <div className="acheiver-blk">
              <div className="acheiver-image">
                <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/defaultImage.jpg" alt="St. Francis School, Ranchi" /></LazyLoad>
              </div>
              <div className="content">
                <h3>{item.name}</h3>
                <p>{item.class}</p>
              </div>
              <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/birthday.gif" className="balon-gif" alt="St. Francis School, Ranchi" /></LazyLoad>
            </div>
          </div>
        )))}
      </Slider>
    </>
  );
}

export default Birthday;
