import React from "react";
import LazyLoad from "react-lazyload";
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear(); 
    return(
        <>
        <footer>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row"> 
                    <div className="col-xxl-4 col-xl-3 col-md-4 col-lg-6 col-md-6">
                    <div className="ftr-logo">
                       <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/footerlogo.png"  className="img-fluid" alt="St. Francis School  Harmu, Ranchi " /></LazyLoad>
                          <p>St. Francis School, Ranchi, founded in 1978, is an English Medium Co-educational School established and conducted by T.O.R. FRANCISCAN SOCIETY,  a registered Charitable Society, having its headquarters at Ranchi, Jharkhand.   </p>
                  
                          <div className="social-media"><ul><li><a href="/" target="_blank">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/twitter.png" alt="St. Francis School, Ranchi"/></LazyLoad>
                            </a></li><li><a href="/" target="_blank">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/insta.png" alt="St. Francis School, Ranchi"/></LazyLoad>
                                </a></li><li><a href="/" target="_blank">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/facebook.png" alt="St. Francis School, Ranchi"/></LazyLoad>
                                    </a></li></ul></div>
                          </div>
                </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                            <div className="quick-link">
                                <h3>Quick Links</h3>
                                <ul>
                                <li><Link to='/'>Alumni</Link></li> 
                                <li><Link to='/Tc'>TC Issued</Link></li>  
                                <li><Link to="/Pphelp">Portal (Help?)</Link></li>
                                <li><Link to='/ErpSoftware'>ERP Software</Link></li> 
                                <li><Link to='/'>Sitemap</Link></li>  
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-2 col-xl-3 col-lg-6 col-md-6">
                            <div className="cont-dtl">
                                <h3>Our Location</h3>
                                <p><LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/locicn.png" className="img-fluid"/> </LazyLoad> <Link to="https://maps.app.goo.gl/eK6rAgoepe4bKAPQ8" target="_blank">St. Francis School Harmu, Ranchi - 834002, Jharkhand</Link> </p>
                                <p><LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/telicn.png" className="img-fluid"/> </LazyLoad>  <Link to="tel:8987705291">8987705291 </Link> &nbsp; |  &nbsp;<Link to="tel:9472708434"> 9472708434 </Link> </p>
                                <p><LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/mailicn.png" className="img-fluid" /> </LazyLoad> <Link to="mailto:sfs_harmu@hotmail.com">  sfs_harmu@hotmail.com</Link>   </p>
                                 
                            </div>
                        </div> 
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                            <div className="d-flex">
                                <div className="app-cont">
                                    <p><b>Download Mobile App</b><br></br> A Common Platform For Educators, <b></b></p>
                                    <div>
                                    <a href='https://apps.apple.com/in/app/campuscare-10x/id1611283814' target='_blank'><LazyLoad> <img src="https://webapi.entab.info/api/image/STFSR/public/Images/app1.png" className="img-fluid"/></LazyLoad></a>
                            <a href='https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN' target='_blank'><LazyLoad> <img src="https://webapi.entab.info/api/image/STFSR/public/Images/app2.png" className="img-fluid"/></LazyLoad></a>
                                    </div>
                                    <p className="link">
                                         sfsr.campussoft.net 
                                    </p>
                                </div>
                                <div className="phone-img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/app3.png" /></LazyLoad>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            
            </footer>
            <div className="copyright">
            <p> ©️ Copyright 2019-{currentYear} St. Francis School, Ranchi. All Rights Reserved |  Created By <Link className="link" to="https://www.entab.in" target="_blank"> Entab Infotech : CampusCare® </Link></p>
        </div>
        </>
    )
}
export default Footer;