import { useEffect, useState } from "react";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { getGalleryInner } from "../Service/Api"; 
import { Link } from 'react-router-dom' 
import LazyLoad from 'react-lazyload';

const Gallery = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");  // Defaulting to "All"
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
  
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    useEffect(() => {
      const fetchData = async () => {
        const galleryData1 = await getGalleryInner();
        const galleryData = galleryData1.filter(item => item.category === 'PhotoGallery');
        setData(galleryData);
  
        const yearsSet = new Set();
        const monthsSet = new Set();
  
        galleryData.forEach(item => {
          if (item.date) {
            const date = new Date(item.date); 
            const getmonth = date.getMonth();
            yearsSet.add(date.getFullYear());
            monthsSet.add(date.getMonth());
          }
        });
  
        const uniqueYearRanges = Array.from(yearsSet).sort((a, b) => b - a);
        setYearRanges(uniqueYearRanges);
  
        const monthOptions = Array.from(monthsSet).sort((a, b) => a - b).map(month => monthNames[month]);
        setMonthOptions(["All", ...monthOptions]);
  
        // Default to the current year if it's available in the data
        const currentYear = new Date().getFullYear();
        if (!uniqueYearRanges.includes(currentYear)) {
          setSelectedYear("All");  // If current year is not available, show all
        } else {
          setSelectedYear(currentYear);
        }
      };
  
      fetchData();
    }, []);
  
    useEffect(() => {
      let filteredData = data;
  
      if (selectedYear !== "All") {
        filteredData = filteredData.filter(item => {
          const itemDate = item.date?.trim();
          if (itemDate) {
            const date = new Date(itemDate);
            const year = date.getFullYear();
            const month = date.getMonth();
            return year === parseInt(selectedYear) && (selectedMonth === "All" || monthNames[month] === selectedMonth);
          }
          return false;
        });
      } else if (selectedMonth !== "All") {
        filteredData = filteredData.filter(item => {
          const itemDate = item.date?.trim();
          if (itemDate) {
            const month = new Date(itemDate).getMonth();
            return monthNames[month] === selectedMonth;
          }
          return false;
        });
      }
  
      if (searchQuery) {
        filteredData = filteredData.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
      }
  
      // Sort the filtered data by date in descending order
      filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
  
      setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);
  
    const handleModal = id => {
      navigate(`/SubGallery?id=${id}`);
    };
  
    const emptyArray = [
       {id:1},
       {id:2},
       {id:3},
    ];
  
  return (
     <>
       
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>
            <li> Gallery </li> 
        </ul>
    </div>
    <div className="innersec">
    <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Gallery </h1>
                </div>
            </div>
            <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="count-val">
                <p>Total Categories: {filterData.length}</p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection">
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="monthSelect">
                  {monthOptions.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>   
          <div className="row years year2024 showYear">
          {filterData.length > 0 ? (
            filterData.map((item, index) => {
              const date = new Date(item.date);
              const day = date.getDate();
              const month = monthNames[date.getMonth()];
              return (
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount" key={index}>
                  <div className="gallery-inner">
                    <div className="gallery-blks">
                      <div className="backDiv"></div>
                      <LazyLoad> <img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} className="img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></LazyLoad> 
                    </div>
                    <div className="gallery-content">
                      <div className="gallery-date">
                        <h6>{day} <span>{month}</span></h6>
                        <div className="gall-num">
                          <h5><span>Number Of Photos :</span>{item?.attachments.length}</h5>
                        </div>
                      </div>
                      <div className="gallery-title">
                        <h3>{item.title}</h3>
                      </div>
                      <a onClick={() => navigate(`/SubGallery?id=${item._id}`)} className="view-more">View More <i className="bi bi-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            emptyArray.map((data) => (
            
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount " key={data.id}>
                <div className="gallery-inner">
                  <div className="gallery-blks">
                    <div className="backDiv"></div>
                    <LazyLoad><img src="https://webapi.entab.info/api/image/STFSR/public/Images/gal3.jpg" className="img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></LazyLoad>
                  </div>
                  <div className="gallery-content">
                    <div className="gallery-date">
                      <h6>DD<span> Mon </span> </h6>
                      <div className="gall-num">
                        <h5><span>Number Of Photos :</span>NO</h5>
                      </div>
                    </div>
                    <div className="gallery-title">
                      <h3>title</h3>
                    </div>
                    <Link to="/SubGallery" className="view-more">View More <i className="bi bi-arrow-right"></i></Link>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
    </div>
    </div>
  </>
  );
};

export default Gallery;
